import React from "react"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import moment from "moment"
import "moment/locale/de"

import Layout from "../components/layout"
import SEO from "../components/seo"
import OpenTimes from "../components/opentimes"

import "./kontakt.scss"
import { graphql } from "gatsby"

moment.locale("de")

const KontaktPage = ({ data: { sanityContactSettings: data } }) => {
  return (
    <Layout page="kontakt">
      <SEO title="Kontakt und Anfahrt" />
      <div className="display-header">
        <h2>Kontakt und Anfahrt</h2>
      </div>
      <Row>
        <Col xs={12} className="content">
          <p>
            <b>Gosch's Gasthof</b>
          </p>
          <p>
            <i className="fas fa-phone-alt"></i>{" "}
            <a href={`tel:${data.phone}`} title="Jetzt anrufen">
              {data.phone}
            </a>
          </p>
          <p>
            <i className="fas fa-map-marker-alt"></i> {data.address.street},{" "}
            {data.address.zip} {data.address.city}
          </p>
          <p className="text-muted small">
            <i className="fas fa-info-circle"></i> Reservierungen können wir
            ausschließlich telefonisch entgegen nehmen.
          </p>
        </Col>
        <Col xs={12}>
          <hr />
          <div className="display-header">
            <h2>Öffnungszeiten</h2>
          </div>
          <OpenTimes />
          <hr />
        </Col>
        <Col xs={12}>
          <div className="map-container">
            <iframe
              title="Gosch's Gasthof auf Google Maps"
              src={data.mapsEmbedUrl}
              width="100%"
              height="300"
              frameborder="0"
              style={{ border: 0 }}
              allowfullscreen
            />
          </div>
        </Col>
      </Row>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ContactPageSettingsQuery {
    sanityContactSettings(_id: { eq: "contactSettings" }) {
      address {
        zip
        street
        city
      }
      facebook
      instagram
      phone
      mapsEmbedUrl
    }
  }
`

export default KontaktPage
